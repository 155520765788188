<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">Request List </v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="() => this.fetchCheckers()">
        <v-icon class="mr-1">mdi-reload</v-icon>
        <div class="reload-text">Reload</div>
      </v-btn>
    </v-toolbar>

    <v-tabs v-model="selectedTab" fixed-tabs centered dark background-color="primary">
      <v-tab v-for="tab in tabs" :key="tab"> {{ tab }}</v-tab>
    </v-tabs>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      class="pa-5"
      hide-details
    ></v-text-field>
    <div class="pa-1">
      Search result for:
      <strong>
        {{ search }}
      </strong>
    </div>
    <v-row justify="center" :key="verdict">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :search="search"
          :items="checkers"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          item-key="id"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          @click:row="(item) => openDetailsOf(item)"
          style="cursor: pointer"
        >
          <template v-slot:[`item.checkerId`]="{ item }">
            <v-btn
              v-if="item.checkerId"
              outlined
              :to="{ name: 'user.view', params: { id: item.checkerId } }"
            >
              {{ item.checkerId }}
            </v-btn>
            <p v-else class="ma-3">-</p>
          </template>
          <template v-slot:[`item.makerId`]="{ item }">
            <v-btn outlined :to="{ name: 'user.view', params: { id: item.makerId } }">
              {{ item.makerId || "-" }}
            </v-btn>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.verdict`]="{ item }">
            <v-chip
              small
              class="white--text"
              :color="{ APPROVED: 'success', REJECTED: 'red' }[item.verdict] || 'info'"
            >
              {{ item.verdict || "Pending" }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center">
              <VerdictButton v-if="verdict === 'Pending'" small :id="item.id" />
              <v-btn small rounded outlined @click.stop="openDetailsOf(item)" class="ma-1">
                More
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CHECKER_LIST } from "@/constants/api";
import VerdictButton from "@/views/components/Checker/VerdictButton.vue";

export default {
  name: "CheckerIndex",
  metaInfo: { title: "Checker" },
  components: {
    VerdictButton,
  },
  data: () => ({
    search: "",
    loading: false,
    tabs: ["Pending", "Approved", "Rejected"],
    checkers: [],
    totalItems: null,
    optionsLength: 6,
    footerProps: { "items-per-page-options": [50, 100] },
  }),
  computed: {
    verdict: {
      get() {
        return this.$route.query.verdict || "Pending";
      },
      set(verdict) {
        this.pushCurrentRoute({ verdict, page: 1 });
      },
    },
    page: {
      get() {
        return Number(this.$route.query.page) || 1;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: val });
      },
    },
    itemsPerPage: {
      get() {
        return Number(this.$route.query.limit) || 10;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: 1, limit: val });
      },
    },
    selectedTab: {
      get() {
        return this.tabs.indexOf(this.verdict) || 0;
      },
      set(value) {
        this.pushCurrentRoute({ verdict: this.tabs[value], page: 1 });
      },
    },
    headers() {
      if (this.verdict === "Pending") {
        return [
          { text: "Maker Id", value: "makerId" },
          { text: "Verdict", value: "verdict" },
          { text: "Maker Decision", value: "makerDecision" },
          { text: "Created At", value: "createdAt" },
          { text: "Name", value: "name" },
          { text: "NID", value: "nid", filterable: true, sortable: true },
          { text: "Actions", value: "actions" },
        ];
      } else {
        return [
          { text: "Checker Id", value: "checkerId" },
          { text: "Maker Id", value: "makerId" },
          { text: "Verdict", value: "verdict" },
          { text: "Maker Decision", value: "makerDecision" },
          { text: "Name", value: "name" },
          { text: "NID", value: "nid", filterable: true, sortable: true },
          { text: "Created At", value: "createdAt" },
          { text: "Actions", value: "actions" },
        ];
      }
    },
  },
  watch: {
    $route({ name }) {
      if (name === "checker.index") {
        this.fetchCheckers();
      }
    },
  },
  mounted() {
    this.fetchCheckers();
  },
  methods: {
    openDetailsOf(item) {
      // this.$router.push({ name: "checker.view", params: { id: item.id } }).catch(() => {});
      let routeData = this.$router.resolve({ name: "cbs.view", params: { id: item.onboardingId } });
      window.open(routeData.href, "_blank");
    },
    async pushCurrentRoute({ page, limit, verdict }) {
      await this.$nextTick();

      const q = {};
      q.page = page === undefined ? this.page : page;
      q.limit = limit === undefined ? this.itemsPerPage : limit;
      q.verdict = verdict === undefined ? this.verdict : verdict;
      Object.keys(q).forEach((el) => {
        if (!q[el]) delete q[el];
      });

      this.$router.push({ name: this.$route.name, query: q }).catch(() => {});
    },
    async fetchCheckers() {
      this.loading = true;
      try {
        this.checkers = [];
        const params = {
          page: this.page - 1,
          limit: this.itemsPerPage,
        };
        if (this.verdict && this.verdict !== "Pending") {
          params.verdict = this.verdict.toUpperCase();
        }
        const response = await this.$axios.get(CHECKER_LIST, { params });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.totalItems = response.data.data.totalItems;
        this.checkers = response.data.data.items.map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
