<template>
  <div>
    <v-card class="text-center grey--text px-3 py-1 rounded" v-if="loading">
      <v-progress-circular indeterminate size="22" />
    </v-card>
    <div v-else v-show="canShowButtons">
      <v-btn
        rounded
        dark
        :small="small"
        color="success"
        class="ma-1"
        :width="small ? 80 : 125"
        @click.stop="updateVerdict('APPROVED')"
      >
        Approve
      </v-btn>
      <v-btn
        rounded
        :small="small"
        dark
        color="red"
        class="ma-1"
        :width="small ? 80 : 125"
        @click.stop="updateVerdict('REJECTED')"
      >
        Reject
      </v-btn>
    </div>
  </div>
</template>

<script>
import startCase from "lodash.startcase";
import { CHECKER_VERDICT } from "@/constants/api";

export default {
  props: {
    small: { type: Boolean, required: false },
    id: { type: [String, Number], required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    canShowButtons() {
      return this.verifyPrivilege(CHECKER_VERDICT);
    },
  },
  methods: {
    async updateVerdict(verdict) {
      if (!verdict) {
        return;
      }
      this.loading = true;
      try {
        const response = await this.$axios.patch(
          `${CHECKER_VERDICT}?requestId=${this.id}&verdict=${verdict}`
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.$iziToast.success({ title: `Request ${startCase(verdict.toLowerCase())}` });
        this.$router.push({
          name: "checker.index",
          query: { verdict: startCase(verdict.toLowerCase()) },
        });
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
